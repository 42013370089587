import { Colors, FontSizes, TrufoButton, centeredAbsoluteStyle } from '@components';
import { Box, CircularProgress } from '@mui/material';
import { UiEncodingSettings } from '@types';
import { EncodeCheckbox, EncodeCheckboxDropdown, EncodeCheckboxInput, EncodeCheckboxToggle } from './input-components';
import { ReactNode, useState } from 'react';
import { noEncodingMethodSelected } from '../utils';

type EncodeFormProps = {
    encodingSettings: UiEncodingSettings,
    onEncodingSettingsChange: (newEncodingSettings: UiEncodingSettings) => void;
    previewImage: () => void,
    previewImageFile: File | undefined,
    previewImageLoading: boolean,
    settingsModal: ReactNode;
    openSettingsModal: () => void;
}

export default function EncodeForm({ encodingSettings, onEncodingSettingsChange, previewImage, previewImageFile, previewImageLoading, settingsModal, openSettingsModal }: EncodeFormProps) {
    const es = encodingSettings;
    const props = { encodingSettings, onEncodingSettingsChange };
    const [previewDirty, setPreviewDirty] = useState<boolean>(false);
    const previewing = previewImageFile && !previewDirty;

    return (
        <>
            {settingsModal}

            {/* INFO */}
            <Box
                position='relative'
                display='flex' height='calc(100% - 50px)' width='100%' gap='1dvh'
                justifyContent='center' alignItems='center' flex={2}
                flexDirection='column'
            >
                <Box position='absolute' left='10px' top='10px' fontSize={FontSizes.header3} >[ Info ]</Box>

                <Box display='flex' flexDirection='column' alignItems='flex-start' marginTop='40px'>
                    <EncodeCheckbox {...props} variant='includeUserDetails' openSettingsModal={openSettingsModal} />
                    <EncodeCheckboxDropdown {...props} variant='origination' />
                    <EncodeCheckboxDropdown {...props} variant='contentLicense' />
                    <EncodeCheckbox {...props} variant='doNotTrain' />
                    <EncodeCheckboxInput {...props} variant='name' selected={Boolean(es.name?.selected)} />
                    <EncodeCheckboxInput {...props} variant='description' selected={Boolean(es.description?.selected)} />
                </Box>
            </Box>

            <hr style={{ width: '100%', borderTop: `1px dashed ${Colors.accentDark}`, borderBottom: 'none', margin: '0px' }} />

            {/* METHOD */}
            <Box
                position='relative'
                display='flex' height='calc(100% - 50px)' width='100%' gap='1dvh'
                justifyContent='center' alignItems='center' flex={1}
                flexDirection='column'
            >
                <Box position='absolute' left='10px' top='10px' width='calc(100% - 20px)' display='flex' justifyContent='space-between' alignItems='center' gap='10px'>
                    <Box fontSize={FontSizes.header3}>[ Method ]</Box>
                    <TrufoButton
                        onClick={() => { previewImage(); setPreviewDirty(false); }}
                        disabled={noEncodingMethodSelected(es) || previewing}
                        colorVariant='accentDark'
                    >
                        {previewImageLoading &&
                            <Box sx={{ ...centeredAbsoluteStyle, top: '10%' }}>
                                <CircularProgress size={FontSizes.header3} sx={{ color: Colors.black70 }} />
                            </Box>}
                        <Box visibility={previewImageLoading ? 'hidden' : 'visible'}>{previewing ? 'previewing' : 'preview'}</Box>
                    </TrufoButton>
                </Box>
                <Box display='flex' flexDirection='column' alignItems='flex-start' marginTop='30px'>
                    <EncodeCheckboxToggle {...props} variant='invisible' onEncodingSettingsChange={(newVal) => { onEncodingSettingsChange(newVal); setPreviewDirty(true); }} />
                    <EncodeCheckboxToggle {...props} variant='metadata' />
                </Box>
            </Box >
        </>
    );
}