import { useGoogleAnalytics } from '@hooks';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ErrorPage, MainApp, LandingPage, PrivacyPolicy, TermsOfService, LoginPage, PricingPage, PeoplePage, ProductsPage, AboutPage, ResearchPage } from '@pages';
import { AisicPresentation } from './pages/publications/aisic-presentation';
import { CpcpsPage } from './pages/landing-page/cpcps-page';

export const EMPTY_ROUTE = '/#';
export const TERMS_OF_SERVICE_ROUTE = '/terms-of-service';
export const PRIVACY_POLICY_ROUTE = '/privacy-policy';
export const LOGIN_ROUTE = '/login';
export const LANDING_PAGE_ROUTE = '/';
export const MAIN_APP_ROUTE = '/app';
export const AISIC_TF21_PRESENTATION_SLIDES_ROUTE = '/aisic/building-a-robust-system-for-content-authentication';
export const AISIC_TF21_PRESENTATION_SLIDES_ROUTE2 = '/resources/aisic-202409'
export const AISIC_TF21_PRESENTATION_RECORDING_ROUTE = '/resources/aisic-202409-recording'
export const WHITEPAPER_ROUTE = '/solutions-to-the-content-authenticity-problem.pdf'
export const VERIFY_ROUTE = '/verify';
export const PRODUCTS_ROUTE = '/products';
export const PRICING_ROUTE = '/pricing';
// export const PRESS_ROUTE = '/press';
export const PUBLICATIONS_ROUTE = '/publications'; // for C2PA soft binding link (backwards compatibility)
export const RESEARCH_ROUTE = '/research';
export const ABOUT_ROUTE = '/about';
export const PEOPLE_ROUTE = '/people';
export const CPCPS_ROUTE = '/repository/cpcps';
export const DOCUMENTATION_ROUTE = '/docs'

export const SUPPORT_EMAIL = 'mailto:support@trufo.ai';
export const PRIVACY_CONTACT_EMAIL = 'mailto:privacy@trufo.ai';
export const CONTACT_US_EMAIL = 'mailto:contact@trufo.ai';
export const PARTNERSHIP_EMAIL = 'mailto:collabs@trufo.ai';
export const CAREERS_EMAIL = 'mailto:careers@trufo.ai';
export const PRESS_EMAIL = 'mailto:press@trufo.ai';

export const BLOG_LINK = 'https://medium.com/trufo';
export const DISCORD_LINK = 'https://discord.gg/BeZxSK5fFk';
export const X_LINK = 'https://twitter.com/TrufoAI';
export const INSTAGRAM_LINK = 'https://www.instagram.com/trufo.ai/';
export const CC_LICENSE_LINK = 'https://creativecommons.org/share-your-work/cclicenses/';
export const FEATURE_REQUEST_LINK = 'https://docs.google.com/forms/d/e/1FAIpQLSfGNMm6ComRA8C0KtNi-WFdV2dsHvM-e2UnD-IDeiU1GlDHWg/viewform?vc=0&c=0&w=1&flr=0';
export const AISIC_LINK = 'https://www.nist.gov/artificial-intelligence/artificial-intelligence-safety-institute';
export const CAI_LINK = 'https://contentauthenticity.org/';
export const C2PA_LINK = 'https://c2pa.org/';
export const VISION_LINK = 'https://medium.com/p/ae4afed08930';
export const YOUTUBE_LINK = 'https://www.youtube.com/@trufo-ai';
export const DEMO_REQUEST_LINK = 'https://docs.google.com/forms/d/e/1FAIpQLSckRBstp-9NL5Zp73t1_iRMkc_YHsI1cUhapF690xXN0MTmhQ/viewform?usp=sf_link';
export const BLOG_WATERMARK_LINK = 'https://medium.com/p/d98b78e6f808';
export const BLOG_DIFF_LINK = 'https://medium.com/p/14cf03bed707';
export const BLOG_C2PA_LINK = 'https://medium.com/p/464d0d875f64';
export const BLOG_SCALABILITY_LINK = 'https://medium.com/p/beecdd718120';
export const BLOG_INTRO_LINK = 'https://medium.com/p/e70ba3fb8cbe';
export const ERASING_THE_INVISIBLE_LINK = 'https://erasinginvisible.github.io/';

export const GET_STARTED_HREF = DEMO_REQUEST_LINK; // TODO: change this to app when app supports self-onboarding

export default function AppRoutes() {

  // useGoogleAnalytics();

  const router = createBrowserRouter([
    {
      path: "/",
      element: <LandingPage />,
      errorElement: <ErrorPage />
    },
    {
      path: LANDING_PAGE_ROUTE,
      element: <LandingPage />,
      errorElement: <ErrorPage />
    },
    {
      path: MAIN_APP_ROUTE,
      element: <MainApp />,
      errorElement: <ErrorPage />
    },
    {
      path: VERIFY_ROUTE,
      element: <MainApp />,
      errorElement: <ErrorPage />
    },
    {
      path: LOGIN_ROUTE,
      element: <LoginPage />,
      errorElement: <ErrorPage />
    },
    {
      path: PRODUCTS_ROUTE,
      element: <PricingPage />, // TODO: switch to products page
      errorElement: <ErrorPage />
    },
    {
      path: PRICING_ROUTE,
      element: <PricingPage />,
      errorElement: <ErrorPage />
    },
    {
      path: PUBLICATIONS_ROUTE,
      element: <ResearchPage />, // for C2PA soft binding link (backwards compatibility)
      errorElement: <ErrorPage />
    },
    {
      path: RESEARCH_ROUTE,
      element: <ResearchPage />,
      errorElement: <ErrorPage />
    },
    {
      path: PEOPLE_ROUTE,
      element: <PeoplePage />,
      errorElement: <ErrorPage />
    },
    {
      path: ABOUT_ROUTE,
      element: <AboutPage />,
      errorElement: <ErrorPage />
    },
    // {
    //   path: PRESS_ROUTE,
    //   element: <PressPage />,
    //   errorElement: <ErrorPage />
    // },
    // {
    //   path: CPCPS_ROUTE,
    //   element: <CpcpsPage />,
    //   errorElement: <ErrorPage />
    // },
    {
      path: PRIVACY_POLICY_ROUTE,
      element: <PrivacyPolicy />,
      errorElement: <ErrorPage />
    },
    {
      path: TERMS_OF_SERVICE_ROUTE,
      element: <TermsOfService />,
      errorElement: <ErrorPage />
    },
    {
      path: AISIC_TF21_PRESENTATION_SLIDES_ROUTE,
      element: <AisicPresentation />,
      errorElement: <ErrorPage />
    },
    {
      path: AISIC_TF21_PRESENTATION_SLIDES_ROUTE2,
      element: <AisicPresentation />,
      errorElement: <ErrorPage />
    },
    {
      path: AISIC_TF21_PRESENTATION_RECORDING_ROUTE,
      element: <ErrorPage variant='construction' />,
      errorElement: <ErrorPage />
    }
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}
