import { ApiAvReturnMimeType, ApiOrigination, ApiContentLicense, UiWmSettingsInvisible, UiWmSettingsMetadata, ApiDecodingRequest, ApiEncodingRequest, UiEncodingSettings, FileType, ImageInfo, ApiUserLogin, ApiAvFormat, ApiWmType, ApiWmSettings, ApiUsageStipulation } from '@types';

export const createEncodingRequest = (encodingSettings: UiEncodingSettings, imageBase64: string, imageInfo: ImageInfo, login: ApiUserLogin, isPreview: boolean): ApiEncodingRequest => {
    // TODO: enable toggling 'include user details'
    const es = encodingSettings;

    const contentLicenseCC = es.contentLicense?.selected ? es.contentLicense?.val : undefined;
    let contentLicense = contentLicenseCC || ApiContentLicense.NONE;
    const usageStipulations: Partial<Record<ApiUsageStipulation, boolean>> = {};
    if (es.doNotTrain) {
        usageStipulations['do-not-train'] = true;
    }

    const wmSettings: ApiWmSettings = {};
    if (es.invisible?.selected) {
        const key = es.invisible?.val === UiWmSettingsInvisible.G1_IMAGE ? ApiWmType.G1_IMAGE : ApiWmType.G1_IMAGE_LITE;
        wmSettings[key] = {};
    }
    if (es.metadata?.selected) {
        const key = es.metadata?.val === UiWmSettingsMetadata.G0_META ? ApiWmType.G0_META : ApiWmType.G0_META_C2PA;
        wmSettings[key] = {};
    }

    const ret: ApiEncodingRequest = {
        authData: login,
        avData: imageBase64?.split(',')[1] || '',
        avReturnMimeType: imageInfo.type === FileType.PNG ? ApiAvReturnMimeType.PNG : ApiAvReturnMimeType.JPG,
        avFormat: ApiAvFormat.IMAGE,
        wmSettings: wmSettings,
        contentInfo: {
            displayName: es.name?.selected ? es.name?.val : undefined,
            description: es.description?.selected ? es.description?.val : undefined,
            origination: es.origination?.selected ? es.origination?.val : ApiOrigination.NONE,
            contentLicense: contentLicense,
            usageStipulations: usageStipulations,
        },
        isPreview,
    }

    return ret;
}

export const createDecodingRequest = (imageBase64: string): ApiDecodingRequest => {

    const ret: ApiDecodingRequest = {
        avData: imageBase64?.split(',')[1] || '',
        avFormat: ApiAvFormat.IMAGE,
    };

    return ret;
}

export const getContentLicenseTags = (contentLicense: string): string[] => {

    const substringTagMappings: Record<string, string> = {
        'CC': 'creative commons',
        '0' : 'no rights reserved',
        'BY': 'attribution required',
        'SA': 'share-alike (copyleft)',
        'NC': 'no commercial usage',
        'ND': 'no derivative works',
        'do-not-train': 'no AI training',
    }
    const substrings = Object.keys(substringTagMappings);
    const tags: string[] = [];
    substrings.forEach(substring => {
        if (contentLicense.includes(substring)) tags.push(substringTagMappings[substring]);
    })
    return tags;
}

export const noEncodingMethodSelected = (encodingSettings: UiEncodingSettings) => {
    const es = encodingSettings;
    return !es.invisible?.selected && !es.metadata?.selected;
}