// Account Auth Types
import { JwtPayload } from "jwt-decode";
export interface JwtPayloadGoogleExtras {
    azp?: string[] | string;
    email?: string;
    email_verified?: boolean;
    family_name?: string;
    given_name?: string;
    hd?: string;
    locale?: string;
    name?: string;
}
export type JwtPayloadGoogle = JwtPayload & JwtPayloadGoogleExtras;

// File Upload Types
export enum FileType {
    JPG = 'image/jpg',
    PNG = 'image/png',
    JPEG = 'image/jpeg',
    WEBP = 'image/webp',
    GIF = 'image/gif',
    TIF = 'image/tif',
    TIFF = 'image/tiff',
    BMP = 'image/bmp',
    HEIC = 'image/heic',
    HEIF = 'image/heif',
    AVIF = 'image/avif'
}

export enum Method {
    ENCODE = 'encode',
    DECODE = 'decode',
}

export interface ImageAspectRatio {
    width: number,
    height: number,
}
export type ImageInfo = {
    name: string,
    type: FileType | string,
} & ImageAspectRatio;

export interface ImageBase64 {
    base64: string,
    type: FileType,
}

export const isTypeofImageBase64 = (variable: any): boolean => {
    return 'base64' in variable && 'type' in variable;
}

// API Types

export enum ApiAvReturnMimeType {
    JPG = 'image/jpeg',
    PNG = 'image/png',
    MP4 = 'video/mp4',
}

export enum ApiAvFormat {
    IMAGE = 'image/bytes',
    VIDEO = 'video/bytes',
}

export type ApiWmSettings = { [key in ApiWmType]?: any; };

export enum ApiWmType {
    G0_META         = "trufo.gen0.meta",
    G0_META_C2PA    = "trufo.gen0.meta-c2pa",

    G1_IMAGE        = "trufo.gen1.image",
    G1_IMAGE_LITE   = "trufo.gen1.image-lite",
}

export enum UiWmSettingsInvisible {
    NONE            = 'none',
    G1_IMAGE        = 'NORMAL',
    G1_IMAGE_LITE   = 'LITE',
}
export enum UiWmSettingsMetadata {
    NONE            = 'none',
    G0_META         = 'NORMAL',
    G0_META_C2PA    = 'C2PA',
}

export enum ApiOrigination {
    NONE    = 'none',
    AI      = 'ai-generated',
    CAMERA  = 'camera-captured',
    HUMAN   = 'human-created',
};
export const CONTENT_LABEL_COPY: Record<ApiOrigination, string> = {
    [ApiOrigination.NONE]: 'None',
    [ApiOrigination.AI]: 'AI Generated',
    [ApiOrigination.CAMERA]: 'Camera Captured',
    [ApiOrigination.HUMAN]: 'Human Created',
}

export enum ApiContentLicense {
    NONE = 'none',
    CC_ZERO = 'CC.0',
    CC = 'CC.BY',
    CC_SA = 'CC.BY-SA',
    CC_NC = 'CC.BY-NC',
    CC_NC_SA = 'CC.BY-NC-SA',
    CC_ND = 'CC.BY-ND',
    CC_NC_ND = 'CC.BY-NC-ND',
}

export type ApiContentLicenseCC = Extract<ApiContentLicense,
    ApiContentLicense.CC_ZERO | ApiContentLicense.CC | ApiContentLicense.CC_SA | ApiContentLicense.CC_NC
    | ApiContentLicense.CC_NC_SA | ApiContentLicense.CC_ND | ApiContentLicense.CC_NC_ND>

export enum ApiUsageStipulation {
    DO_NOT_TRAIN = 'do-not-train',
}

export interface ApiContentInfo {
    displayName?: string, // max length 70
    description?: string, // max length 700
    origination?: ApiOrigination,
    contentLicense?: ApiContentLicense,
    usageStipulations?: Partial<Record<ApiUsageStipulation, boolean>>,
}
export const MAX_LENGTH_CONTENT_NAME = 70;
export const MAX_LENGTH_CONTENT_DESCRIPTION = 700;

export interface ApiSourceInfo {
    uniqueHandle?: string, // max 70 char
    description?: string, // max 700 char
}
export const MIN_LENGTH_SOURCE_UNIQUE_HANDLE = 5;
export const MAX_LENGTH_SOURCE_UNIQUE_HANDLE = 70;
export const MAX_LENGTH_SOURCE_DESCRIPTION = 700;

// ENCODING

export interface ApiEncodingRequest {
    authData: ApiUserLogin,
    avData: string, // b64-utf8 of PNG/JPG
    avReturnMimeType: ApiAvReturnMimeType,
    avFormat: ApiAvFormat,
    wmSettings: ApiWmSettings,
    contentInfo: ApiContentInfo,
    isPreview?: boolean,
}

export interface UiEncodingSettings {
    includeUserDetails?: boolean,
    name?: {
        selected?: boolean,
        val?: string // max length 70
    },
    description?: {
        selected?: boolean,
        val?: string // max length 700
    },
    origination?: {
        selected?: boolean,
        val?: ApiOrigination,
    }
    contentLicense?: {
        selected?: boolean,
        val?: ApiContentLicenseCC,
    },
    doNotTrain?: boolean,
    invisible?: {
        selected?: boolean,
        val?: UiWmSettingsInvisible,
    },
    metadata?: {
        selected?: boolean,
        val?: UiWmSettingsMetadata,
    },
}
export const defaultEncodingSettings: UiEncodingSettings = {
    includeUserDetails: true,
    name: { // This default will be overridden in the encoding page; sets this to the first 70 characters of filename
        selected: true,
        val: '',
    },
    description: {
        selected: false,
        val: '',
    },
    origination: {
        selected: false,
        val: ApiOrigination.NONE,
    },
    contentLicense: {
        selected: false,
        val: ApiContentLicense.CC_ZERO,
    },
    doNotTrain: true,
    invisible: {
        selected: true,
        val: UiWmSettingsInvisible.G1_IMAGE,
    },
    metadata: {
        selected: true,
        val: UiWmSettingsMetadata.G0_META_C2PA,
    },
}
export const ENCODING_SETTINGS_COPY: Record<keyof UiEncodingSettings | 'date' | 'user', string> = {
    includeUserDetails: 'Include User Details',
    name: 'Name',
    description: 'Description',
    origination: 'Origination Label',
    contentLicense: 'Copyright License',
    doNotTrain: 'Do Not Train',
    invisible: 'Invisible',
    metadata: 'Metadata',
    date: 'Date of signature',
    user: 'User of signature',
}

// DECODING

export interface ApiDecodingRequest {
    avData: string, // b64-utf8 of PNG/JPG
    avFormat: ApiAvFormat;
}

export interface ApiDecodingResponse {
    contentIdentification?: {
        creationTime?: number,
        contentType?: string,
        contentInfo?: ApiContentInfo,
        sourceType?: string,
        sourceInfo?: ApiSourceInfo,
    },
    contentVerification?: {
        bytesMatch?: boolean,
        frameMatch?: boolean,
        diffFrame?: string,
    },
    error?: boolean,
    errorDetails?: string,
    // UI parsing
    diffImage?: File | ImageBase64,
}


// USER

export const MAX_LENGTH_USER_NAME = 70;
export const MAX_LENGTH_USER_PASSWORD = 70;

export enum ApiUserAccountType {
    GOOGLE = 't1.app.web.google',
    TRUFO = 't1.app.web.trufo',
}
export interface ApiUserLoginTrufo {
    password: string, // max length 70
    username: string, // max length 70
}
export interface ApiUserLoginGoogle {
    sub: string,
    token?: string, // may go in header later // technically mandatory
    data?: {
        email?: string,
        firstName?: string,
        lastName?: string,
    },
}
export type ApiUserLogin = { accountType: ApiUserAccountType } & (ApiUserLoginGoogle | ApiUserLoginTrufo);

export type ApiUserCreateRequestType = 'REGISTER';

export type ApiUserCreateRequest = {
    authData: ApiUserLogin,
    action: ApiUserCreateRequestType,
    sourceInfo?: ApiSourceInfo,
    accountDetails?: Object, // ignore this for now
}

export type ApiUserRequestType = 'AUTH' | 'FETCH' | 'UPDATE' | 'SIGN';

export type ApiUserRequest = {
    authData: ApiUserLogin,
    action: ApiUserRequestType,
    UPDATE?: {
        newPassword?: string, // only applies to Trufo account
        newSourceInfo?: ApiSourceInfo,
        newAccountDetails?: Object,
    },
    SIGN?: {
        message: string,
    },
}

export interface ApiCreateUserReturn {
    error?: boolean,
    errorDetails?: boolean,
    aid?: string,
    sid?: number,
}

export interface ApiFetchUserReturn {
    error?: boolean,
    errorDetails?: boolean,
    accountType?: string,
    accountInfo?: any,
    accountData?: any, // non user editable
    sourceType?: string,
    sourceInfo?: ApiSourceInfo,
    sourceData?: any, // non user editable
}

export interface UiUserSettings {
    uniqueHandle?: string,
    description?: string,
}
export const USER_SETTINGS_COPY: Record<keyof UiUserSettings, string> = {
    uniqueHandle: 'Unique Handle',
    description: 'Description',
}