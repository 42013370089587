import { ApiOrigination, ApiContentLicense, CONTENT_LABEL_COPY, UiEncodingSettings } from '@types';
import { TrufoCheckbox, TrufoDropdown, TrufoLink, ZIndexes } from '@components';
import { Box, MenuItem } from '@mui/material';
import { CC_LICENSE_LINK } from '@/app-routes';

export type EncodeCheckboxDropdownProps = {
    variant: 'contentLicense' | 'origination',
    encodingSettings: UiEncodingSettings,
    onEncodingSettingsChange: (newVal: UiEncodingSettings) => void,
}

export default function EncodeCheckboxDropdown({ variant, encodingSettings, onEncodingSettingsChange }: EncodeCheckboxDropdownProps) {

    const displayText = {
        contentLicense: 'Copyright License',
        origination: 'Origination Label',
    }

    const dropdownOptions = {
        contentLicense: [
            { val: ApiContentLicense.CC_ZERO, displayText: 'CC.0' },
            { val: ApiContentLicense.CC, displayText: 'CC.BY' },
            { val: ApiContentLicense.CC_SA, displayText: 'CC.BY-SA' },
            { val: ApiContentLicense.CC_NC, displayText: 'CC.BY-NC' },
            { val: ApiContentLicense.CC_NC_SA, displayText: 'CC.BY-NC-SA' },
            { val: ApiContentLicense.CC_ND, displayText: 'CC.BY-ND' },
            { val: ApiContentLicense.CC_NC_ND, displayText: 'CC.BY-NC-ND' },
        ],
        origination: [
            { val: ApiOrigination.CAMERA, displayText: CONTENT_LABEL_COPY[ApiOrigination.CAMERA] },
            { val: ApiOrigination.AI, displayText: CONTENT_LABEL_COPY[ApiOrigination.AI] },
            { val: ApiOrigination.HUMAN, displayText: CONTENT_LABEL_COPY[ApiOrigination.HUMAN] },
        ],
    }

    const obj = encodingSettings[variant];
    const val = obj?.val;
    const selected = obj?.selected;

    return (
        <Box display='flex' alignItems='center' justifyContent='center'>
            <TrufoCheckbox
                checked={selected}
                colorVariant='accentDark'
                onClick={() => onEncodingSettingsChange({ [variant]: { ...obj, selected: !selected } })}
            />
            <Box display='flex' flexDirection='column' alignItems='flex-start' zIndex={ZIndexes.clickable}>
                <Box>{displayText[variant]}</Box>
                {variant === 'contentLicense' &&
                    <Box display='flex'>
                        <Box marginRight='5px'>(learn more</Box>
                        <TrufoLink href={CC_LICENSE_LINK} trufoVariant='accentDark' newtab>here</TrufoLink>
                        <Box>)</Box>
                    </Box>
                }
            </Box>
            <TrufoDropdown
                value={val}
                disabled={!selected}
                sx={{ marginLeft: '10px', width: variant === 'contentLicense' ? '135px' : '180px' }}
                onChange={e => { if (e?.target?.value) onEncodingSettingsChange({ [variant]: { ...obj, val: e.target.value } }) }}
            >
                {dropdownOptions[variant].map(option =>
                    <MenuItem key={option.val} value={option.val}>{option.displayText}</MenuItem>)}
            </TrufoDropdown>
        </Box>
    );
}